<template>
  <div>
    <h3 ref="intro">
      What is Probability?
    </h3>
    <br>
    <p>
      Probability, stated in simple terms, is a way to determine the likelihood of the occurrence of a given event.
      <br><br>
      The higher the probability of a given event, the greater the likelihood of occurrence of that event, and vice-versa.
      <br><br>
      Numerically, the probability of getting a given outcome is expressed as a number between 0 and 1.
      <br><br>
      Probability of 0 means that the outcome is impossible while a probability of 1 implies that the outcome is certain (absolutely likely to happen).
    </p>
    <h3 ref="types">
      Finding Probability of an Event
    </h3>
    <br>
    <p>
      Let's consider a random experiment (say rolling of a die) that can give multiple outcomes (for example &mdash; the rolling of a die can give 1, 2, 3, 4, 5, or 6).
      <br><br>
      Let's say we have to calculate the probability of occurrence of a given outcome (say getting a 2). Below are the steps to find this probability.
    </p>
    <ul class="a mx-3">
      <li> <h5> Step 1: Total Number of Possible Outcomes (<i>N</i> )</h5> </li>
      <p>
        Start with finding the number of all possible outcomes that can come
        out of the random experiment.  Let's call this number <i>N</i>.
      </p>
      <li> <h5> Step 2: Number of Favorable Outcomes (<i>n</i> ) </h5> </li>
      <p>
        Next, we calculate the number of ways in which the desired outcome can be obtained.
        Let's call this number <i>M</i>.
      </p>
      <li> <h5> Step 3: Calculate the Ratio <i>M</i> over <i>N</i></h5> </li>
      <p>
        Calculate the ratio of \(M\) over \(N\). This ratio gives the probability of event A.
        $$\text{Probability of Event A=}P(A) = \frac{M}{N}$$
      </p>
    </ul>
    <h3 ref="pg">
      MagicGraph | Probability Exercise
    </h3>
    <br>
    <h5>Getting Started</h5>
    <p>
      In this MagicGraph, you will learn, in a step by step manner, how to find the probability of getting a certain outcome in a game of dice.
      <br><br>
      Simply use <i class="redoer ma-1" /> and <i class="undoer ma-1" /> icons to go through the steps
      required to calculate the probability in the game of dice.
      <br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle events.
      You can tap on the <i class="eraser ma-1" /> icon to erase and start over.
      Tap on the <i class="redoer ma-1" /> icon to go to the next step.
      To go to the previous step, tap on the <i class="undoer ma-1" /> icon.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Probability',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Probability Exercise';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Proabability',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about Thales Theorem'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
