// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-4, 11, 10, -3],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =13;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['sector'] =15;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Probability in a Game of Dice', '(Probability of Getting an Even Outcome)');
  placeLogo(brd1);
  var reload = placeShuffle(brd1);
  var test = placeTest(brd1);
  //var num =placeInput(brd1, 8, 4.5, 1);
  //var den=placeInput(brd1, 8, 3.5, 4);
  //hoverMe(brd1, num, 'Numerator', 15, 40);
  //hoverMe(brd1, den, 'Denominator', 15, -40);
  //placeLine(brd1, [8, 4], [9,4], 4, 'black');
  //placeText(brd1, 7, 4, 24, 'Fraction =')
  //var dataArr=[()=>num.Value(), ()=>den.Value()-num.Value()];
  var lenArr = [2, 7, 4, 5, 3, 6, 8];
  var fracArr = [1, 3, 3, 2, 2, 4, 5];
  //Red Dice
   placeImage(brd1, '/assets/dice-1-red.svg', -0.35-0.55, 6.5-1, 1,20);//
   placeImage(brd1, '/assets/dice-2-red.svg', 1.75-0.55, 6.5-1, 1,70);//
   placeImage(brd1, '/assets/dice-3-red.svg', 3.5-0.55, 6.65-1, 1,105);//
   placeImage(brd1, '/assets/dice-4-red.svg', 4.35-0.55, 6.5-1, 1,45);//
   placeImage(brd1, '/assets/dice-5-red.svg', 6.1-0.55, 6.5-1, 1, 70);//
   placeImage(brd1, '/assets/dice-6-red.svg', 8.0-0.55, 7.0-1, 1, 120);//
//Grey Dice
  var dice1g = placeImage(brd1, '/assets/1grey.svg', -0.35-0.55, 6.5-1, 1, 20);//
  var dice2g = placeImage(brd1, '/assets/2grey.svg',  1.75-0.55, 6.5-1, 1,70);//
  var dice3g = placeImage(brd1, '/assets/3grey.svg',  3.5-0.55, 6.65-1, 1,105);//
  var dice4g = placeImage(brd1, '/assets/4grey.svg',  4.35-0.55, 6.5-1, 1,45);//
  var dice5g = placeImage(brd1, '/assets/5grey.svg',  6.1-0.55, 6.5-1, 1, 70);//
  var dice6g = placeImage(brd1, '/assets/6grey.svg',  8.0-0.55, 7.0-1, 1, 120);//
//Green Dice
  placeImage(brd1, '/assets/dice-1-green.svg', -0.35-0.55, 6.5-4, 1, 20);//
  placeImage(brd1, '/assets/dice-2-green.svg',  1.75-0.55, 6.5-4, 1, 70);
  placeImage(brd1, '/assets/dice-3-green.svg',  3.5-0.55, 6.65-4, 1,105);
  placeImage(brd1, '/assets/dice-4-green.svg',  4.35-0.55, 6.5-4, 1,45);
  placeImage(brd1, '/assets/dice-5-green.svg',  6.1-0.55, 6.5-4, 1, 70);//
  placeImage(brd1, '/assets/dice-6-green.svg',  8.0-0.55, 7.0-4, 1, 120);//
//Grey Dice
  var dice1gE = placeImage(brd1, '/assets/1grey.svg', -0.35-0.55, 6.5-4, 1, 20);//
  var dice2gE = placeImage(brd1, '/assets/2grey.svg',  1.75-0.55, 6.5-4, 1,70);//
  var dice3gE = placeImage(brd1, '/assets/3grey.svg',  3.5-0.55, 6.65-4, 1,105);//
  var dice4gE = placeImage(brd1, '/assets/4grey.svg',  4.35-0.55, 6.5-4, 1,45);//
  var dice5gE = placeImage(brd1, '/assets/5grey.svg',  6.1-0.55, 6.5-4, 1, 70);//
  var dice6gE = placeImage(brd1, '/assets/6grey.svg',  8.0-0.55, 7.0-4, 1, 120);//
////////////////////////////////////////////////////
   var ir=0; var jr=0; var kr=0; var lr=0; var mr=0; var nr=0; var even=[0,0,0]; var odd=[0,0,0]; var select=[0,0,0,0,0,0];
   dice1g.on('down', function(){dice1g.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*ir/2))});  ir+=1;  select[0]=Math.abs(Math.sin(Math.PI*ir/2)); });
   dice2g.on('down', function(){dice2g.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*jr/2))});  jr+=1;  select[1]=Math.abs(Math.sin(Math.PI*jr/2)); });
   dice3g.on('down', function(){dice3g.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*kr/2))});  kr+=1;  select[2]=Math.abs(Math.sin(Math.PI*kr/2)); });
   dice4g.on('down', function(){dice4g.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*lr/2))});  lr+=1;  select[3]=Math.abs(Math.sin(Math.PI*lr/2)); });
   dice5g.on('down', function(){dice5g.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*mr/2))});  mr+=1;  select[4]=Math.abs(Math.sin(Math.PI*mr/2)); });
   dice6g.on('down', function(){dice6g.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*nr/2))});  nr+=1;  select[5]=Math.abs(Math.sin(Math.PI*nr/2)); });
   var ig=0; var jg=0; var kg=0; var lg=0; var mg=0; var ng=0;
   dice1gE.on('down', function(){dice1gE.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*ig/2))});  ig+=1;   odd[0]=Math.abs(Math.sin(Math.PI*ig/2));});
   dice2gE.on('down', function(){dice2gE.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*jg/2))});  jg+=1;   even[0]=Math.abs(Math.sin(Math.PI*jg/2));});
   dice3gE.on('down', function(){dice3gE.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*kg/2))});  kg+=1;   odd[1]=Math.abs(Math.sin(Math.PI*kg/2));});
   dice4gE.on('down', function(){dice4gE.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*lg/2))});  lg+=1;   even[1]=Math.abs(Math.sin(Math.PI*lg/2));});
   dice5gE.on('down', function(){dice5gE.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*mg/2))});  mg+=1;   odd[2]=Math.abs(Math.sin(Math.PI*mg/2));});
   dice6gE.on('down', function(){dice6gE.setAttribute({fillOpacity:Math.abs(Math.sin(Math.PI*ng/2))});  ng+=1;   even[2]=Math.abs(Math.sin(Math.PI*ng/2));});
   placeMiddleText(brd1, 3, 7.5, 'Step 1: Select All Possible Outcomes');
   placeMiddleText(brd1, 3, 4.5, 'Step 2: Select Even Outcomes');
   var right1 = placeImage(brd1, '/assets/check.svg', 6,7.15,0.75,0);
   var right2 = placeImage(brd1, '/assets/check.svg', 6,4.15, 0.75, 0);
   var right3 = placeImage(brd1, '/assets/check.svg', 6,-0.25, 0.75, 0);

   right1.setAttribute({visible:function(){return select[0]+select[1]+select[2]+select[3]+select[4]+select[5]==6}});
   right2.setAttribute({visible:function(){return (even[0]+even[1]+even[2]==3) && (odd[0]+odd[1]+odd[2]<0.25)}});
   ////////////////////////////////////////////////////////////
   placeMiddleText(brd1, 3, 1.5, 'Step 3: Take the Ratio n over N');
   placeRightText(brd1, 2.8, 0., 'Probability (Even Outcome) =');
   var num =placeInput(brd1, 3.25, 0.5, '', '');
   var den = placeInput(brd1, 3.25, -0.5, '', '');
   placeLine(brd1, [3, 0], [5,0], 2, 'black');
  //var reload =brd1.create('image', ['/assets/shuffle.svg', [-2.25,3.4],[1.2, 1.2]], {opacity:1, fixed:true});
  test.setLabel('Tap to Test Your Answer')
  test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  test.on('over', function () {this.label.setAttribute({visible:true});});
  test.on('out', function () {this.label.setAttribute({visible:false});});
  right3.setAttribute({visible:function(){return (num.Value()/den.Value())==0.5}});
 //test.on('down', function(){let val = test(); if(num.Value()/den.Value()){right.setAttribute({visible:true})}else{wrong.setAttribute({visible:true})}});
  /////////////////////////////////////////////////////////////////////////////////////////////
  reload.setLabel('Tap to Shuffle')
  reload.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){right.setAttribute({visible:false}); wrong.setAttribute({visible:false}); k=Math.min(k+1, 5);ini(); fun();});
  //brd1.create('text', [4, 6.5, 'Select slices that make'],{display:'internal', alignX:'right'});


  //ques.setText();
  //placeMiddleText(brd1, 3.0, 0.5, 'Click on a Slice to Select/Deselect it');
  /////////////////////////////////////////////////////////////////////////////////////////////
  //brd1.create('text', [()=>6+2.75*Math.cos(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Bulldog ('+type1.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Doberman ('+type2.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')' ],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Grehound ('+type3.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Dalmatian ('+type4.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Shar-Pie ('+type5.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
//
/////////////////////
/*var sect=[];
var ini = function(){
brd1.removeObject(sect,true);
}
/////////////////
 var status =[];
 var fun = function(){
 for(let i=0; i<lenArr[k]; i++)
 {
   sect[i] =brd1.create('sector', [[3,4],
    [3+2*Math.cos((i+1)*2*Math.PI/Math.max(lenArr[k],1)), 4+2*Math.sin((i+1)*2*Math.PI/Math.max(lenArr[k], 1))],
    [3+2*Math.cos((i+2)*2*Math.PI/Math.max(lenArr[k],1)), 4+2*Math.sin((i+2)*2*Math.PI/Math.max(lenArr[k], 1))]],
   {strokeColor:'black', strokeWidth:1, fillOpacity:0.});
   status[i] = 0;
   sect[i].setAttribute({dash:2, center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
 }
};
 var test = function()
 { let total=0;
   for(let i=0; i<lenArr[k]; i++)
   {
     total = total + Math.abs(Math.sin(status[i]*Math.PI/2));
   }
   return total;
 }
ini(); fun();

brd1.on('up', function(evt)
{
  let coords = brd1.getMousePosition(evt);
  for(let i=0; i<lenArr[k]; i++)
  {
    if(sect[i].hasPointSector(coords[0], coords[1])==true)
    { status[i] +=1;
      right.setAttribute({visible:false});
      wrong.setAttribute({visible:false});
      sect[i].setAttribute({fillOpacity:Math.abs(Math.sin(status[i]*Math.PI/2)), fillColor:'OldLace'});
    }
  }
);
*/
//brd1.create('text', [6, 6, function(){return coords[0]}]);
 //num.on('down', function(){i=0; ini();fun();brd1.update()});
 //den.on('down', function(){i=0; ini();fun();brd1.update()});
 /////////////////////////////////////////////////////
 /*let magicGraph = document.querySelector("#jxgbox1");
 let inputFields = magicGraph.querySelectorAll("input");
 var onEachPress = function(event) { i=0; ini(); sect=[]; fun();}
    inputFields.forEach(function(field) {
    field.addEventListener('input', onEachPress);});*/
/*  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(2*Math.PI/(den.Value())), ()=>4+2*Math.sin(2*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(4*Math.PI/(den.Value())), ()=>4+2*Math.sin(4*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(6*Math.PI/(den.Value())), ()=>4+2*Math.sin(6*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(8*Math.PI/(den.Value())), ()=>4+2*Math.sin(8*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(10*Math.PI/(den.Value())), ()=>4+2*Math.sin(10*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(12*Math.PI/(den.Value())), ()=>4+2*Math.sin(12*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});*/
//
//reload.on('down', function(){ini();type1=Math.round(Math.random()*3)+1; type2=Math.round(Math.random()*4)+1; type3=Math.round(Math.random()*4)+1;type4=Math.round(Math.random()*2)+1;type5=Math.round(Math.random()*3)+1;
//   i=1;j=1;k=1;l=1;m=1;});
//brd1.unsuspendUpdate();
},
}
export default Boxes;
